import React from "react"
import PageHeadBanner from "../components/PageHeadBanner"
import Seo from "../components/Seo"
import withLayout from "../layout/withLayout"

const RulesAndRegulations = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title={"Rules and Regulations"} siteMetadata={siteData} />
      <PageHeadBanner
        title={"Rules and Regulations"}
        subtitle={"Rules and Regulations in our Academy"}
      />

      <section className="terms-conditions-area ptb-100">
        <div className="container">
          <div className="main-default-content">
            <h3>1. College Uniform</h3>
            <p>
              Proin eget tortor risus. Mauris blandit aliquet elit, eget
              tincidunt nibh pulvinar a. Quisque velit nisi, pretium ut lacinia
              in elementum id enim. Praesent sapien massa, convallis a
              pellentesque nec, egestas non nisi. Cras ultricies ligula sed
              magna dictum porta. Curabitur non nulla sit amet nisl tempus
              convallis quis ac lectus. Vestibulum ac diam sit amet quam
              vehicula elementum sed sit amet dui. Donec rutrum congue leo eget
              malesuada. Donec rutrum congue leo eget malesuada. Pellentesque in
              ipsum id orci porta dapibus. Curabitur aliquet quam id ut lacinia
              in elementum id enim. Praesent sapien massa, convallis a
              pellentesque nec, egestas non nisi leo eget malesuada.
            </p>
            <div className="gap-20"></div>
            <h3>2. Recommendations for Students</h3>
            <ul>
              <li>
                Students must always wear the valid identity card issued by the
                collage during collage hours.
              </li>
              <li>
                Students must attend classes with uniforms recognised by the
                collage
              </li>
              <li>
                During class hours all areas near the class room will remain as
                silent zone
              </li>
              <li>
                Spitting/writing and sticking bills on the college walls are
                strictly prohibited.
              </li>
              <li>
                Spitting/writing and sticking bills on the college walls are
                strictly prohibited.
              </li>
            </ul>

            <div className="gap-20"></div>
            <h3>3. Electronic Gadgets</h3>
            <p>Mobile phones will be allowed with strict restrictions.</p>

            <div className="gap-20"></div>
            <h3>4. Participation in Extracurricular Activities</h3>
            <p>
              In addition to study, the students are encouraged to attend extra
              curricular activities like music, singing, dance, sport, art, etc.
            </p>

            <div className="gap-20"></div>
            <h3>5. Library Room</h3>
            <p>The student should from the habit of reading in the library.</p>

            <div className="gap-20"></div>
            <h3>6. Notice Board and Communications</h3>
            <p>
              The student should go through the notice board for upto date
              information’s.{" "}
            </p>
          </div>
        </div>
      </section>
    </>
  )
})

export default RulesAndRegulations
